import { defaultTo, forEach, get, has, isEmpty, orderBy } from "lodash";
import { DateEnum } from "../enums/DateEnum";
import {
  PaymentMethodLabel,
  PaymentMethodsEnum,
  PaymentMethodsLabelEnum,
  PaymentSubMethodsEnum,
} from "../infraestructure/payment-method";
import { formatNumber } from "./transaction_list_utils";
import {
  PROCESSOR_NAME_MAPPED,
  ProcessorEnum,
} from "../infraestructure/ProcessorEnum";
import {
  ContentCard,
  responseJson,
} from "../../components/CardInformation/CardInformation.interfaces";
import {
  TransactionStatusEnum,
  TransactionStatusLabelEnum,
} from "../enums/TransactionStatusEnum";
import { Transaction, TransactionInfo } from "../../../types/transactions_data";
import {
  AuthenticationDataFieldValueEnum,
  AuthorizationDataFieldValueEnum,
  BillingDataFieldValueEnum,
  CardAlignEnum,
  CardDataFieldValueEnum,
  CardInformationEnum,
  CardSectionValueEnum,
  MerchantDataFieldValueEnum,
  PaymentCredentialDataFieldValueEnum,
  TransactionDataDataFieldValueEnum,
} from "../enums/CardInformationEnum";
import {
  TransactionTypeEnum,
  TransactionTypeLabelEnum,
} from "../enums/TransactionTypeEnum";
import { TransactionType } from "../infraestructure/transaction-type";
import { TimelineTrxData } from "../interfaces/TimeLine.interfaces";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../infraestructure/time-zone-catalogues";
import {
  getCardType,
  getPayType,
  isApproval,
  renderSectionsByTransaction,
} from "./general_utils";
import { getRole } from "./transaction_container_utils";
import { CognitoRolesEnum } from "../infraestructure/CognitoRolesEnum";

export function validationNull(valor: string | null): string {
  return valor === null ? "N/A" : valor;
}

const getClient = (
  getPaymentMethod: string[] | string,
  transactionInfo: TransactionInfo
) => {
  switch (getPaymentMethod) {
    case PaymentMethodsEnum.CASH:
      return get(transactionInfo, "client_name", "N/A");
    case PaymentMethodsEnum.CARD:
      return get(transactionInfo, "card_holder_name", "N/A");
    case PaymentMethodsEnum.TRANSFER:
      return `${get(transactionInfo, "document_type", "-")}-${get(
        transactionInfo,
        "document_number",
        "-"
      )}`;
    default:
      return "";
  }
};
const mapPaymentMethods = (
  paymentMethod: string | undefined,
  isCardPresent: boolean
): string => {
  let paymentLabel: string = "";

  if (!paymentMethod) return paymentLabel;

  if (isCardPresent) paymentLabel = PaymentMethodsEnum.CARD_PRESENT;
  else paymentLabel = paymentMethod;

  return PaymentMethodLabel[paymentLabel];
};
const setPaymentMethods = (
  methods: string[] | string,
  isArrayPayment: boolean,
  isCardPresent: boolean
): string[] => {
  if (isArrayPayment)
    return (methods as string[]).map((method: string) => {
      return mapPaymentMethods(method, isCardPresent);
    });

  return [""];
};

const buildPaymentMethod = (
  payment: string,
  transactionInfo: TransactionInfo
): {
  [k: string]: string;
} => {
  switch (payment) {
    case PaymentMethodsLabelEnum.CARD:
      return {
        label: payment,
        value: get(transactionInfo, "masked_credit_card", ""),
      };
    case PaymentMethodsLabelEnum.TRANSFER:
      return {
        label: CardInformationEnum.BANK,
        value: get(transactionInfo, "bank_name", "N/A"),
      };
    default:
      return {
        label: CardInformationEnum.CLIENT_ID,
        value: get(transactionInfo, "identification", "N/A"),
      };
  }
};

const mapTransactionType = (transactionType: string): string => {
  switch (transactionType) {
    case TransactionTypeEnum.SALE:
      return TransactionTypeLabelEnum.SALE;
    case TransactionTypeEnum.VOID:
      return TransactionTypeLabelEnum.VOID;
    case TransactionTypeEnum.VOID_PARTIAL:
      return TransactionTypeLabelEnum.VOID_PARTIAL;
    case TransactionTypeEnum.DEFFERRED:
      return TransactionTypeLabelEnum.DEFERRED;
    case TransactionTypeEnum.CREDIT:
      return TransactionTypeLabelEnum.CREDIT;
    case TransactionTypeEnum.PAYOUT:
      return TransactionTypeLabelEnum.PAYOUT;
    case TransactionTypeEnum.DEBIT:
      return TransactionTypeLabelEnum.DEBIT;
    case TransactionTypeEnum.CHARGEBACK:
      return TransactionTypeLabelEnum.CHARGEBACK;
    case TransactionTypeEnum.PREAUTHORIZATION:
      return TransactionTypeLabelEnum.PREAUTHORIZATION;
    case TransactionTypeEnum.CAPTURE:
      return TransactionTypeLabelEnum.CAPTURE;
    case TransactionTypeEnum.REFUND:
      return TransactionTypeLabelEnum.REFUND;
    case TransactionTypeEnum.REFUND_PARTIAL:
      return TransactionTypeLabelEnum.REFUND_PARTIAL;
    case TransactionTypeEnum.REFUND_TOTAL:
      return TransactionTypeLabelEnum.REFUND_TOTAL;
    case TransactionTypeEnum.REVERSE:
      return TransactionTypeLabelEnum.REVERSE;
    default:
      return "N/A";
  }
};

const approvalAmount = (
  status: string,
  type: string,
  converted_amount: number,
  request_amount: number,
  transaction_amount: number,
  currency: string
): string => {
  let getApprovalAmount: string;

  if (
    status === TransactionType.DECLINED ||
    type === TransactionType.VOID ||
    type === TransactionType.REVERSE ||
    type === TransactionType.VOID_PARTIAL
  ) {
    if (converted_amount) {
      getApprovalAmount = formatNumber(converted_amount.toString());
    } else {
      if (request_amount) {
        getApprovalAmount = formatNumber(request_amount.toString());
      } else {
        getApprovalAmount = CardInformationEnum.DEFAULT_VALUE;
      }
    }
  } else {
    if (transaction_amount) {
      getApprovalAmount = formatNumber(transaction_amount.toString());
    } else {
      getApprovalAmount = CardInformationEnum.DEFAULT_VALUE;
    }
  }

  return `${getApprovalAmount} ${currency}`;
};
const buildTotal = (
  status: string,
  type: string,
  request_amount: number,
  transaction_amount: number,
  currency: string
): string => {
  let getAmount: string;

  if (
    status === TransactionType.DECLINED ||
    status === TransactionStatusEnum.INITIALIZED ||
    type === TransactionType.VOID ||
    type === TransactionType.REVERSE ||
    type === TransactionType.VOID_PARTIAL
  ) {
    if (request_amount) {
      getAmount = formatNumber(request_amount.toString());
    } else {
      getAmount = CardInformationEnum.DEFAULT_VALUE;
    }
  } else {
    if (transaction_amount) {
      getAmount = formatNumber(transaction_amount.toString());
    } else {
      getAmount = CardInformationEnum.DEFAULT_VALUE;
    }
  }

  return `${getAmount} ${currency}`;
};

const getProcessorName = (transactionInfo: TransactionInfo): string => {
  const currentName = get(transactionInfo, "processor_name", "N/A");

  return get(PROCESSOR_NAME_MAPPED, currentName, currentName);
};

export const jsonToString = (
  transactionInfo: TransactionInfo
): responseJson => {
  const metadata_obj: {
    metadata: object;
    subscriptionMetadata: object;
  } = {
    metadata: get(transactionInfo, "metadata", {}),
    subscriptionMetadata: get(transactionInfo, "subscription_metadata", {}),
  };
  const taxes_obj: {
    taxes: object;
  } = {
    taxes: get(transactionInfo, "taxes", {}),
  };

  const metadataCredentials_obj: {
    metadata: object;
  } = {
    metadata: get(transactionInfo, "credential_metadata", {}),
  };
  let metadata: string;
  let metadataCredentials: string;
  let taxes: string;
  let value_tax: number = 0;

  if (
    get(transactionInfo, "payment_method", "") === PaymentMethodsEnum.CARD ||
    get(transactionInfo, "subscription_id", "") === ""
  ) {
    Reflect.deleteProperty(metadata_obj, "subscriptionMetadata");
  }

  for (let i in taxes_obj.taxes) {
    if (taxes_obj.taxes.hasOwnProperty(i)) {
      value_tax += taxes_obj.taxes[i];
    }
  }
  metadata = JSON.stringify(metadata_obj, null, " ");
  metadataCredentials = JSON.stringify(metadataCredentials_obj, null, " ");
  taxes = JSON.stringify(taxes_obj, null, " ");

  return {
    metadata,
    metadataCredentials,
    taxes,
    value_tax: value_tax.toString(),
  };
};
const getResponseCode = (transactionInfo: TransactionInfo): number => {
  const responseCode = defaultTo(get(transactionInfo, "response_code"), 0);
  const processorCode = get(transactionInfo, "processor_code");

  return processorCode !== undefined && !isEmpty(processorCode)
    ? processorCode
    : responseCode;
};

const getResponseText = (transactionInfo: TransactionInfo): string => {
  const responseText = get(transactionInfo, "response_text", "N/A");
  const processorMessage = get(transactionInfo, "processor_message", "");

  return isEmpty(processorMessage) ? responseText : processorMessage;
};

const buildCardInfo = (
  transactionInfo: TransactionInfo,
  getPaymentMethod: string[] | string,
  isArrayPayment: boolean,
  isCardPresent: boolean,
  subTotal: number,
  currency: string,
  taxes: number,
  isSecondSearch: boolean = false
): ContentCard[] => {
  let data: ContentCard[] = [] as ContentCard[];
  const isBackOfficeAdminRole: boolean = getRole(
    CognitoRolesEnum.BACK_OFFICE_ADMIN
  );

  const paymentMethod: string =
    typeof getPaymentMethod === "string"
      ? mapPaymentMethods(getPaymentMethod, isCardPresent)
      : mapPaymentMethods("", isCardPresent);
  const parent: Transaction[] = get(transactionInfo, "parent[0]", []);
  const total_trx: string = get(
    transactionInfo,
    "parent[0]._source.approved_transaction_amount"
  );

  if (!isEmpty(transactionInfo)) {
    if (!isSecondSearch) {
      data = [
        {
          body: [
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.MERCHANT_NAME,
              text: get(transactionInfo, "merchant_name", ""),
              type: MerchantDataFieldValueEnum.MERCHANT_NAME,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.MERCHANT_ID,
              text: get(transactionInfo, "merchant_id", ""),
              type: MerchantDataFieldValueEnum.MERCHANT_ID,
            },
          ],
          title: CardInformationEnum.MERCHANT_DATA,
          type: CardSectionValueEnum.MERCHANT_DATA,
        },
        {
          body: [
            {
              alignText: CardAlignEnum.RIGHT,
              label:
                getPaymentMethod === PaymentMethodsEnum.TRANSFER
                  ? CardInformationEnum.DOC_NUMBER
                  : CardInformationEnum.CLIENT,
              text: getClient(getPaymentMethod, transactionInfo),
              type: TransactionDataDataFieldValueEnum.CLIENT_DOC_NUMBER,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              isArray: Array.isArray(getPaymentMethod),
              itemText: setPaymentMethods(
                getPaymentMethod,
                isArrayPayment,
                isCardPresent
              ),
              label: CardInformationEnum.PAYMENT_METHOD,
              text: paymentMethod,
              type: TransactionDataDataFieldValueEnum.PAYMENT_METHOD,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: buildPaymentMethod(paymentMethod, transactionInfo).label,
              text: buildPaymentMethod(paymentMethod, transactionInfo).value,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.TYPE,
              text: mapTransactionType(
                get(transactionInfo, "transaction_type", "")
              ),
              type: TransactionDataDataFieldValueEnum.TYPE,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.APPROVAL_NO,
              text: get(transactionInfo, "approval_code", "N/A"),
              type: TransactionDataDataFieldValueEnum.APPROVAL_NO,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.SUBTOTAL,
              text: `${formatNumber(subTotal.toString())} ${currency}`,
              type: TransactionDataDataFieldValueEnum.SUBTOTAL,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.TAXES,
              text: `${formatNumber(taxes.toString())} ${currency}`,
              type: TransactionDataDataFieldValueEnum.TAXES,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.TOTAL,
              text: buildTotal(
                get(transactionInfo, "transaction_status")!,
                mapTransactionType(
                  get(transactionInfo, "transaction_type", "")
                ),
                get(transactionInfo, "request_amount")!,
                get(transactionInfo, "approved_transaction_amount")!,
                currency
              ),
              type: TransactionDataDataFieldValueEnum.TOTAL,
            },
            isBackOfficeAdminRole
              ? {
                  alignText: CardAlignEnum.RIGHT,
                  label: CardInformationEnum.KUSHKI_ID,
                  text: get(transactionInfo, "transaction_card_id", ""),
                  type: TransactionDataDataFieldValueEnum.KUSHKI_ID,
                }
              : {},
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.TOTAL_TRANSACTION,
              text: `${formatNumber(total_trx)} ${currency}`,
              type: TransactionDataDataFieldValueEnum.TOTAL_TRANSACTION,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.TOTAL_REFUND,
              text: buildTotal(
                get(transactionInfo, "transaction_status")!,
                mapTransactionType(
                  get(transactionInfo, "transaction_type", "")
                ),
                get(transactionInfo, "request_amount")!,
                get(transactionInfo, "approved_transaction_amount")!,
                currency
              ),
              type: TransactionDataDataFieldValueEnum.TOTAL_REFUND,
            },
          ],
          title: CardInformationEnum.TRANSACTION_DATA,
          type: CardSectionValueEnum.TRANSACTION_DATA,
        },
        {
          body: [
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.CREDENTIAL,
              text: get(transactionInfo, "credential_alias", ""),
              type: PaymentCredentialDataFieldValueEnum.CREDENTIAL,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.CREDENTIAL_ID,
              text: get(transactionInfo, "credential_id", ""),
              type: PaymentCredentialDataFieldValueEnum.CREDENTIAL_ID,
            },
            {
              alignText: CardAlignEnum.RIGHT,
              label: CardInformationEnum.PUBLIC_KEY,
              text: get(transactionInfo, "public_credential_id", ""),
              type: PaymentCredentialDataFieldValueEnum.PUBLIC_KEY,
            },
          ],
          title: CardInformationEnum.PAYMENT_CREDENTIAL_DATA,
          type: CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA,
        },
        {
          body: [
            {
              label: CardInformationEnum.SUBTOTAL_IVA,
              text: `${formatNumber(
                defaultTo(
                  get(
                    transactionInfo,
                    "converted_subtotal_iva",
                    get(transactionInfo, "subtotal_iva", 0)
                  ),
                  0
                ).toString()
              )} ${currency}`,
              type: BillingDataFieldValueEnum.SUBTOTAL_IVA,
            },
            {
              label: CardInformationEnum.SUBTOTAL_IVA_0,
              text: `${formatNumber(
                defaultTo(
                  get(
                    transactionInfo,
                    "converted_subtotal_iva0",
                    get(transactionInfo, "subtotal_iva0", 0)
                  ),
                  0
                ).toString()
              )} ${currency}`,
              type: BillingDataFieldValueEnum.SUBTOTAL_IVA_0,
            },
            {
              label: CardInformationEnum.IVA_AMOUNT,
              text: `${formatNumber(
                defaultTo(
                  get(
                    transactionInfo,
                    "converted_iva",
                    get(transactionInfo, "iva_value", 0)
                  ),
                  0
                ).toString()
              )} ${currency}`,
              type: BillingDataFieldValueEnum.IVA_AMOUNT,
            },
            {
              label: CardInformationEnum.ICE_AMOUNT,
              text: `${formatNumber(
                defaultTo(get(transactionInfo, "ice_value", 0), 0).toString()
              )} ${currency}`,
              type: BillingDataFieldValueEnum.ICE_AMOUNT,
            },
            {
              label: CardInformationEnum.APPROVAL_AMOUNT,
              text: approvalAmount(
                get(transactionInfo, "transaction_status")!,
                mapTransactionType(
                  get(transactionInfo, "transaction_type", "")
                ),
                get(transactionInfo, "converted_amount")!,
                get(transactionInfo, "request_amount")!,
                get(transactionInfo, "approved_transaction_amount")!,
                currency
              ),
              type: BillingDataFieldValueEnum.APPROVAL_AMOUNT,
            },
          ],
          title: CardInformationEnum.BILLING_DATA,
          type: CardSectionValueEnum.BILLING_DATA,
        },
        {
          body: [
            {
              label: CardInformationEnum.CARD_TYPE,
              text: getCardType(get(transactionInfo, "card_type")),
              type: CardDataFieldValueEnum.CARD_TYPE,
            },
            {
              label: CardInformationEnum.PAY_TYPE,
              text: getPayType(get(transactionInfo, "number_of_months", 0)),
              type: CardDataFieldValueEnum.PAY_TYPE,
            },
            {
              label: CardInformationEnum.ISSUING_BANK,
              text: get(transactionInfo, "issuing_bank", "N/A"),
              type: CardDataFieldValueEnum.ISSUING_BANK,
            },
          ],
          title: CardInformationEnum.CARD_DATA,
          type: CardSectionValueEnum.CARD_DATA,
        },
        {
          body: [
            {
              label: CardInformationEnum.PROCESSOR,
              text: validationNull(getProcessorName(transactionInfo)),
              type: AuthorizationDataFieldValueEnum.PROCESSOR,
            },
            {
              label: CardInformationEnum.RESPONSE_CODE,
              text: validationNull(getResponseCode(transactionInfo).toString()),
              type: AuthorizationDataFieldValueEnum.RESPONSE_CODE,
            },
            {
              label: CardInformationEnum.RESPONSE_MESSAGE,
              text: validationNull(getResponseText(transactionInfo)),
              type: AuthorizationDataFieldValueEnum.RESPONSE_MESSAGE,
            },
            {
              label: CardInformationEnum.DESCRIPTION,
              text: validationNull(
                get(transactionInfo, "payment_description", "-")
              ),
              type: AuthorizationDataFieldValueEnum.DESCRIPTION,
            },
          ],
          title: CardInformationEnum.AUTHORIZATION_DATA,
          type: CardSectionValueEnum.AUTHORIZATION_DATA,
        },
        {
          body: [
            {
              label: CardInformationEnum.PROCESSOR,
              text: validationNull(getProcessorName(transactionInfo)),
              type: AuthenticationDataFieldValueEnum.PROCESSOR,
            },
            {
              label: CardInformationEnum.RESPONSE_CODE,
              text: validationNull(getResponseCode(transactionInfo).toString()),
              type: AuthenticationDataFieldValueEnum.RESPONSE_CODE,
            },
            {
              label: CardInformationEnum.RESPONSE_MESSAGE,
              text: validationNull(getResponseText(transactionInfo)),
              type: AuthenticationDataFieldValueEnum.RESPONSE_MESSAGE,
            },
            {
              label: CardInformationEnum.DESCRIPTION,
              text: validationNull(
                get(transactionInfo, "payment_description", "-")
              ),
              type: AuthenticationDataFieldValueEnum.DESCRIPTION,
            },
          ],
          title: CardInformationEnum.AUTHENTICATION_DATA,
          type: CardSectionValueEnum.AUTHENTICATION_DATA,
        },
      ];
    } else {
      data = [
        {
          body: [
            {
              label:
                getPaymentMethod === PaymentMethodsEnum.TRANSFER
                  ? CardInformationEnum.DOC_NUMBER
                  : CardInformationEnum.CLIENT,
              text: getClient(getPaymentMethod, transactionInfo),
            },
            {
              label: CardInformationEnum.TYPE,
              text: mapTransactionType(
                get(transactionInfo, "transaction_type", "")
              ),
            },
            {
              label: !isEmpty(parent)
                ? CardInformationEnum.TOTAL_TRANSACTION
                : undefined,
              text: !isEmpty(parent)
                ? `${formatNumber(total_trx)} ${currency}`
                : undefined,
            },
            {
              label: CardInformationEnum.TOTAL_REFUND,
              text: buildTotal(
                get(transactionInfo, "transaction_status")!,
                mapTransactionType(
                  get(transactionInfo, "transaction_type", "")
                ),
                get(transactionInfo, "request_amount")!,
                get(transactionInfo, "approved_transaction_amount")!,
                currency
              ),
            },
          ],
          title: CardInformationEnum.PAYMENT_CREDENTIAL_DATA,
          type: CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA,
        },
      ];
    }
  }

  return renderSectionsByTransaction(transactionInfo, data);
};

export const buildCardInformation = (
  transactionInfo: TransactionInfo,
  isSecondSearch: boolean = false
): ContentCard[] => {
  const getPaymentMethod: string[] | string = get(
    transactionInfo,
    "payment_method",
    ""
  );
  const getPaymentSubMethod: string = get(
    transactionInfo,
    "channel",
    get(transactionInfo, "payment_submethod_type", "")
  );
  const isArrayPayment: boolean = Array.isArray(getPaymentMethod);
  const isCardPresent: boolean = [
    <string>PaymentSubMethodsEnum.CARD_PRESENT,
    PaymentSubMethodsEnum.POS,
  ].includes(getPaymentSubMethod);
  const currency: string = isEmpty(get(transactionInfo, "converted_currency"))
    ? get(transactionInfo, "currency_code", "")
    : get(transactionInfo, "converted_currency", "");
  const subTotalIva: number = get(
    transactionInfo,
    "converted_subtotal_iva",
    get(transactionInfo, "subtotal_iva", 0)
  );
  const subTotalIavO: number = get(
    transactionInfo,
    "converted_subtotal_iva0",
    get(transactionInfo, "subtotal_iva0", 0)
  );
  const subTotal: number = subTotalIva + subTotalIavO;
  const ivaValue: number = get(
    transactionInfo,
    "converted_iva",
    get(transactionInfo, "iva_value", 0)
  );
  const iceValue: number = get(transactionInfo, "ice_value", 0);

  const taxes: number =
    ivaValue + iceValue + Number(jsonToString(transactionInfo).value_tax);

  return buildCardInfo(
    transactionInfo,
    getPaymentMethod,
    isArrayPayment,
    isCardPresent,
    subTotal,
    currency,
    taxes,
    isSecondSearch
  );
};

const descriptionStatusTrx = (
  statusTrx: string,
  requestAmount: number,
  approvalAmount: number,
  trxAmount: number,
  type: string
): { amount: number; title: string } => {
  const is_approval: boolean = isApproval(statusTrx);

  if (is_approval) {
    if (type === TransactionType.REFUND) {
      if (trxAmount === approvalAmount) {
        return {
          amount: approvalAmount,
          title: CardInformationEnum.FULL_REFUND_REQUESTED,
        };
      }

      return {
        amount: approvalAmount,
        title: CardInformationEnum.PARTIAL_REFUND_REQUESTED,
      };
    }

    return {
      amount: approvalAmount,
      title: CardInformationEnum.REFUND_REQUESTED,
    };
  }

  return trxAmount === requestAmount
    ? {
        amount: requestAmount,
        title: CardInformationEnum.REFUND_REQUESTED,
      }
    : {
        amount: requestAmount,
        title: CardInformationEnum.PARTIAL_REFUND_REQUESTED,
      };
};

export const buildTimeLine = (
  transactionInfo: TransactionInfo
): TimelineTrxData[] => {
  const data: TimelineTrxData[] = [];
  let typeDescription: { title: string; amount: number } = {
    amount: 0,
    title: "",
  };
  let is_partial: boolean = false;
  let is_refund: boolean = false;
  let is_process: boolean = false;

  const array_transaction_types: string[] = [
    TransactionTypeEnum.VOID,
    TransactionTypeEnum.VOID_PARTIAL,
    TransactionTypeEnum.REFUND,
    TransactionTypeEnum.REFUND_TOTAL,
    TransactionTypeEnum.REFUND_PARTIAL,
    TransactionTypeEnum.REVERSE,
  ];

  const array_status_cash_timeline: string[] = [
    TransactionStatusEnum.APPROVAL,
    TransactionStatusEnum.APPROVED,
    TransactionStatusEnum.INITIALIZED,
    TransactionStatusEnum.DECLINED,
  ];

  const payment_method: string = get(transactionInfo, "payment_method", "");
  const transaction_status: string = get(
    transactionInfo,
    "transaction_status",
    ""
  );

  const transaction_type: string = get(transactionInfo, "transaction_type", "");
  const transaction_created: string = get(transactionInfo, "created", "");
  const is_approval: boolean = isApproval(transaction_status);

  if (
    payment_method === PaymentMethodsEnum.CARD &&
    is_approval &&
    transaction_type === TransactionTypeEnum.SALE
  ) {
    data.push({
      completed: true,
      date: transaction_created,
      link: false,
      title: TransactionTypeLabelEnum.APPROVAL_TRANSFER,
    });

    if (
      has(transactionInfo, "child") &&
      get(transactionInfo, "child", []).length > 0
    ) {
      forEach(get(transactionInfo, "child", []), (child: Transaction) => {
        const is_child_approval: boolean = isApproval(
          get(child, "_source.transaction_status")
        );

        typeDescription = descriptionStatusTrx(
          get(child, "_source.transaction_status"),
          get(child, "_source.request_amount", 0),
          get(child, "_source.approved_transaction_amount", 0),
          is_child_approval
            ? get(transactionInfo, "approved_transaction_amount", 0)
            : get(transactionInfo, "request_amount", 0),
          get(child, "_source.transaction_type")
        );

        data.push({
          completed: is_child_approval,
          date: get(child, "_source.created", ""),
          link: true,
          subtitle1: is_child_approval
            ? TransactionStatusLabelEnum.APPROVAL
            : TransactionStatusLabelEnum.INITIALIZED,
          subtitle2: `${
            CardInformationEnum.AMOUNT_REQUESTED
          }: ${typeDescription.amount.toFixed(2)} ${get(
            child,
            "_source.currency_code",
            ""
          )}`,
          ticketNumber: get(child, "_source.ticket_number", ""),
          title: typeDescription.title,
        });
      });
    }
  }

  if (
    payment_method === PaymentMethodsEnum.CARD &&
    array_transaction_types.includes(transaction_type)
  ) {
    const parent: Transaction[] = get(transactionInfo, "parent", []);
    let history_declined: TransactionInfo[] = get(
      transactionInfo,
      "historical",
      []
    ).filter(
      (declined: TransactionInfo) =>
        declined.transaction_status === TransactionStatusEnum.DECLINED
    );
    let history_init: TransactionInfo[] = get(
      transactionInfo,
      "historical",
      []
    ).filter(
      (declined: TransactionInfo) =>
        declined.transaction_status === TransactionStatusEnum.INITIALIZED
    );

    if (history_declined.length > 0) {
      history_declined.sort(function (a, b) {
        if (get(a, "_source.created") > get(b, "_source.created")) {
          return 1;
        }
        if (get(a, "_source.created") < get(b, "_source.created")) {
          return -1;
        }

        return 0;
      });
    }

    data.push({
      completed: true,
      date: transaction_created,
      link: false,
      title: CardInformationEnum.REQUEST_RECEIVED,
    });
    data.push({
      completed: false,
      link: false,
      title: CardInformationEnum.REQUEST_IN_PROGRESS,
    });
    data.push({
      completed: false,
      link: false,
      title: CardInformationEnum.REFUND_MADE,
    });

    if (transactionInfo !== undefined) {
      is_refund = [
        TransactionType.REFUND_PARTIAL,
        TransactionType.REFUND_TOTAL,
        TransactionType.REFUND,
      ].includes(transaction_type as TransactionType);
      is_process = transaction_status === TransactionStatusEnum.PROCESS;
      const is_transbank_declined =
        get(transactionInfo, "processor_name", "N/A") ===
          ProcessorEnum.TransbankProcessor &&
        transaction_status === TransactionStatusEnum.DECLINED;
      const is_refund_declined =
        is_refund && transaction_status === TransactionStatusEnum.DECLINED;

      if (parent.length > 0)
        is_partial = is_refund
          ? get(transactionInfo, "approved_transaction_amount", 0) !==
            parent[0]._source!.approved_transaction_amount
          : false;

      if (is_approval) {
        data[0] = {
          ...data[0],
          date: get(history_init[0], "created", ""),
        };
        data[1] = {
          ...data[1],
          completed: true,
          date:
            history_declined.length === 0
              ? transaction_created
              : get(history_declined[0], "created", ""),
        };
        data[2] = {
          ...data[2],
          completed: true,
          date: transaction_created,
          subtitle2: !is_refund
            ? `${CardInformationEnum.RETURNED_AMOUNT}: ${get(
                transactionInfo,
                "request_amount",
                0
              ).toFixed(2)} ${get(transactionInfo, "currency_code", "")}`
            : CardInformationEnum.BANK_LEGEND,
          title: is_refund
            ? is_partial
              ? CardInformationEnum.PARTIAL_REFUND_PROCESSED
              : CardInformationEnum.FULL_REFUND_PROCESSED
            : CardInformationEnum.REFUND_MADE,
        };
      }

      if (is_refund_declined) {
        data[0] = {
          ...data[0],
          date: get(history_init[0], "created", ""),
        };
        data[1] = {
          ...data[1],
          completed: true,
          date: transaction_created,
          subtitle2: CardInformationEnum.AWAIT_BANK_CONFIRMATION,
        };
        data[2] = {
          ...data[2],
          completed: true,
          date: transaction_created,
          title: CardInformationEnum.REFUND_DECLINED,
        };
      }

      if (is_process || is_transbank_declined) {
        data[0] = {
          ...data[0],
          date: get(history_init[0], "created", ""),
        };
        data[1] = {
          ...data[1],
          completed: true,
          date: transaction_created,
          subtitle2: CardInformationEnum.AWAIT_BANK_CONFIRMATION,
        };
      }
    }
  }

  const payment_method_type = get(transactionInfo, "payment_method", "");

  if (
    payment_method_type !== PaymentMethodsEnum.CARD &&
    transaction_type === TransactionTypeEnum.SALE &&
    array_status_cash_timeline.includes(transaction_status)
  ) {
    data.push({
      completed: true,
      date: transaction_created,
      link: false,
      title: CardInformationEnum.INITIALIZED,
    });
    if (is_approval || transaction_status === TransactionStatusEnum.DECLINED) {
      const transaction_completed: string = get(
        transactionInfo,
        "completed",
        DateEnum.DEFAULT
      );
      const transaction_expired: string = get(
        transactionInfo,
        "expired",
        DateEnum.DEFAULT
      );

      const completed_date: string =
        transaction_expired !== DateEnum.DEFAULT &&
        transaction_status === TransactionStatusEnum.DECLINED &&
        transaction_completed === DateEnum.DEFAULT
          ? transaction_expired
          : transaction_completed;

      data.push({
        completed: true,
        date: completed_date,
        link: false,
        title: is_approval
          ? CardInformationEnum.APPROVAL
          : CardInformationEnum.DECLINED,
      });
    }
  }

  return orderBy(data, "date", "asc");
};

export const timeZoneHour = (): string => {
  const text: string | undefined = localStorage.getItem("time-zone")!;
  const value: string = "region";
  let index: number;
  const _timeZoneDefault: string = "America/Guayaquil";

  if (text === undefined) return value === "utc" ? "-05:00" : _timeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === "utc"
      ? "-05:00"
      : _timeZoneDefault;
  }

  return value === "utc" ? "-05:00" : _timeZoneDefault;
};
